import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h2>Ready to experience?</h2>
        <p>Start checking your assets in seconds with a 30-day free trial</p>
        <div className="footer-content-buttons buttons">
          <Link to="/tool" className="btn btn-primary">
            Try it out
          </Link>
          <button className="btn btn-secondary">View demo</button>
        </div>
      </div>

      <div className="footer-details">
        <span>ProdVisual © 2024 – Part of Cape</span>
        <a href="/terms">Terms and Conditions</a>
      </div>
    </footer>
  );
};

export default Footer;
