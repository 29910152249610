// scrollAnimation.js
import { useEffect, useRef, useState } from "react";

const useScrollAnimation = () => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(true);
            observer.unobserve(entry.target); // Stop observing after animation
          }
        });
      },
      { threshold: 0.3 }, // Trigger animation when 30% visible
    );

    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  return [domRef, isVisible];
};

export default useScrollAnimation;
