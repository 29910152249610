// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool.sidebar {
  position: relative;
  width: calc(100vw - 416px);
}
.tool.sidebar .tool-top-bar {
  width: calc(100vw - 416px);
}
.tool__placeholder {
  padding-top: 12em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tool__placeholder__text {
  display: flex;
  width: 638px;
  padding: 16px var(--none, 0px) 8px var(--none, 0px);
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}
.tool__limit-popup {
  display: flex;
}
.tool__limit-popup-box {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 50vw;
  height: 50vh;
  margin: 16em auto;
  position: relative;
  padding: 1em;
}
.tool__limit-popup-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}

.tool__list {
  padding-top: 6em;
}`, "",{"version":3,"sources":["webpack://./src/controllers/Tool/style.scss"],"names":[],"mappings":"AAII;EACI,kBAAA;EACA,0BAAA;AAHR;AAOQ;EACI,0BAAA;AALZ;AAaI;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAXR;AAaQ;EACI,aAAA;EACA,YAAA;EACA,mDAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AAXZ;AAeI;EACI,aAAA;AAbR;AAgBI;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AAdR;AAiBI;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AAfR;;AAkBA;EACI,gBAAA;AAfJ","sourcesContent":[".tool {\n    // width: 100vw;\n    // height: 100vh;\n\n    &.sidebar {\n        position: relative;\n        width: calc(100vw - 416px);\n\n  \n    \n        .tool-top-bar {\n            width: calc(100vw - 416px);\n          \n            \n            \n        }\n    \n}\n\n    &__placeholder {\n        padding-top: 12em;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n\n        &__text {\n            display: flex;\n            width: 638px;\n            padding: 16px var(--none, 0px) 8px var(--none, 0px);\n            flex-direction: column;\n            align-items: center;\n            gap: 8px;\n            text-align: center;\n        }\n    }\n\n    &__limit-popup {\n        display: flex;\n    }\n\n    &__limit-popup-box {\n        background: white;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        text-align: left;\n        width: 50vw;\n        height: 50vh;\n        margin: 16em auto;\n        position: relative;\n        padding: 1em;\n    }\n\n    &__limit-popup-close {\n        position: absolute;\n        top: 0.5em;\n        right: 0.5em;\n    }\n}\n.tool__list {\n    padding-top: 6em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
