// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Topbar Styling */
.topbar {
  position: absolute;
  width: 100%;
  padding: var(--space-32) var(--space-24);
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 100;
}

.logo {
  display: flex;
}
.logo img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/controllers/Home/components/Topnav/style.scss"],"names":[],"mappings":"AAAA,mBAAA;AACA;EACE,kBAAA;EACA,WAAA;EACA,wCAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":["/* Topbar Styling */\n.topbar {\n  position: absolute;\n  width: 100%;\n  padding: var(--space-32) var(--space-24);\n  display: flex;\n  box-sizing: border-box;\n  justify-content: space-between;\n  z-index: 100;\n}\n\n.logo {\n  display: flex;\n  img {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
