// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 2px solid #444;
  border-radius: 16px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 100%);
  backdrop-filter: blur(2px);
  width: fit-content;
  transition: border-color 0.2s ease;
}
.inputContainer:hover {
  border-color: #666;
}
.inputContainer.focused {
  border-color: #8e00ff;
}
.inputContainer .input {
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  padding: 1rem;
  width: 400px;
}
.inputContainer .input.empty::placeholder {
  color: #aaa;
}`, "",{"version":3,"sources":["webpack://./src/controllers/Home/components/Inputfield/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,mBAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,+FAAA;EAKA,0BAAA;EACA,kBAAA;EACA,kCAAA;AAJF;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,qBAAA;AALJ;AAQE;EACE,uBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;AANJ;AAQI;EACE,WAAA;AANN","sourcesContent":[".inputContainer {\n  display: flex;\n\n  align-items: center;\n  padding: 8px;\n  border: 2px solid #444;\n  border-radius: 16px;\n  background: linear-gradient(\n    90deg,\n    rgba(255, 255, 255, 0.05) 0%,\n    rgba(255, 255, 255, 0.1) 100%\n  );\n  backdrop-filter: blur(2px);\n  width: fit-content;\n  transition: border-color 0.2s ease;\n\n  &:hover {\n    border-color: #666;\n  }\n\n  &.focused {\n    border-color: #8e00ff; // Border color when focused\n  }\n\n  .input {\n    background: transparent;\n    border: none;\n    outline: none;\n    color: white;\n    font-size: 1rem;\n    padding: 1rem;\n    width: 400px;\n\n    &.empty::placeholder {\n      color: #aaa; // Placeholder color\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
