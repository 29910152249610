import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#3A82FA'
        },
        background: {
            paper: '#ffffff',
            default: '#F3F5F8'
        },
        text: {
            primary: '#333333',
            secondary: '#919297'
        },
        action: {
            active: '#000000B8'
        },
        divider: '#E0E0E0'
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        fontFamily: 'Inter, Arial, sans-serif', // Set global font
        h1: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '32px'
        },
        body1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '16px'
        },
        body2: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20.02px',
            letter: '0.17px'
        },
        h5: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px'
        },
        h3: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '16px'
        }

        // You can customize other typography variants similarly
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.8em',
                    textTransform: 'none'
                }
            }
        }
        /*
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white", // Set background color
          borderRadius: "1em", // Increase border radius
        },
        notchedOutline: {
          borderRadius: "1em", // Ensure the outline also has the border radius
        },
      },
    },*/
    }
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark', // Specifies dark mode
        primary: {
            main: '#3A82FA'
        },
        background: {
            paper: '#24272D',
            default: '#FFFFFF1F'
        },
        text: {
            primary: '#ffffff',
            secondary: '#FFFFFFB2'
        },
        action: {
            active: '#FFFFFF8F'
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        fontFamily: 'Inter, Arial, sans-serif',
        h1: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '32px'
        },
        body1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '16px',
            color: '#ffffff' // Dark mode text color
        },
        h5: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            color: '#ffffff'
        },
        h3: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '16px',
            color: '#ffffff'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.8em',
                    textTransform: 'none'
                }
            }
        }
    }
});

export { lightTheme, darkTheme };
