import workStepImage1 from "./assets/work-step-1.png";
import workStepImage2 from "./assets/work-step-2.png";
import workStepImage3 from "./assets/work-step-3.png";
import listBullet from "./assets/list-bullet-squarcle.svg";
import useScrollAnimation from "../../hooks/scrollintoAnimaton";
import "../../animations.scss";

import "./style.scss";

const ProductExplain = () => {
  const [workstepRef1, isWorkstep1Visible] = useScrollAnimation();
  const [workstepRef2, isWorkstep2Visible] = useScrollAnimation();
  const [workstepRef3, isWorkstep3Visible] = useScrollAnimation();

  return (
    <section className="work">
      <header>
        <h2>How it works</h2>
        <p>The future of content creation is here</p>
      </header>
      <div
        ref={workstepRef1}
        className={`work-step work-step-left fade-in-right ${isWorkstep1Visible ? "active" : ""}`}
      >
        <div className="text-content">
          <h3>Get product information</h3>
          <p>
            Provide the link to your product, and Cape AI will analyze it in
            seconds.
          </p>
          <ul>
            <li>
              <img src={listBullet} alt="Cape Element" />
              Product information
            </li>
            <li>
              <img src={listBullet} alt="Cape Element" />
              Product content
            </li>
          </ul>
        </div>
        <div className="image-content">
          <div className="wrapper wrapper-left">
            <img src={workStepImage1} alt="Step 1" />
          </div>
        </div>
      </div>

      <div
        ref={workstepRef2}
        className={`work-step work-step-right fade-in-left ${isWorkstep2Visible ? "active" : ""}`}
      >
        <div className="image-content">
          <div className="wrapper wrapper-right">
            <img src={workStepImage2} alt="Step 2" />
          </div>
        </div>
        <div className="text-content">
          <h3>Prepare content</h3>
          <p>
            Select from a wide range of beautifully designed templates tailored
            for your brand.
          </p>
          <ul>
            <li>
              <img src={listBullet} alt="Cape Element" />
              Select gathered product images
            </li>
            <li>
              <img src={listBullet} alt="Cape Element" />
              Edit output settings
            </li>
          </ul>
        </div>
      </div>

      <div
        ref={workstepRef3}
        className={`work-step work-step-left fade-in-right ${isWorkstep3Visible ? "active" : ""}`}
      >
        <div className="text-content">
          <h3>Finalize & Download</h3>
          <p>
            Watch as Cape AI generates stunning product ads with customized
            content and visuals!
          </p>
          <ul>
            <li>
              <img src={listBullet} alt="Cape Element" />
              Generate more
            </li>
            <li>
              <img src={listBullet} alt="Cape Element" />
              Download
            </li>
          </ul>
        </div>
        <div className="image-content">
          <div className="wrapper wrapper-left">
            <img src={workStepImage3} alt="Step 3" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductExplain;
