import React, { useEffect, useState } from 'react';
import { Button, IconButton, TextField, Slider, Typography, Box, Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.scss';
import { GeneratedItem } from 'types';

interface SidebarProps {
    item: GeneratedItem;
    activeImage: number;
    setActiveImage: React.Dispatch<React.SetStateAction<number>>;
    onClose: () => void;
    onSubmit: (data: any) => void;
    src: string;
}

const SidebarFineTune: React.FC<SidebarProps> = ({ item, activeImage, setActiveImage, onClose, onSubmit }) => {
    const [format, setFormat] = useState<'square' | 'portrait' | 'landscape'>('square');
    const [xAxis, setXAxis] = useState<number>(50);
    const [yAxis, setYAxis] = useState<number>(50);
    const [scale, setScale] = useState<number>(70);
    const [promptContent, setPromptContent] = useState<string[]>([]);

    useEffect(() => {
        if (item.imagePrompts) {
            const allPrompts = item.imagePrompts.map((img) => img.prompt);
            setPromptContent(allPrompts);
        }
    }, [item.imagePrompts]);

    const handlePromptChange = (index: number, value: string) => {
        const updatedPrompts = [...promptContent];
        updatedPrompts[index] = value;
        setPromptContent(updatedPrompts);
    };

    return (
        <Box className="sidebar-fine-tune" sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            <div className="sidebar-fine-tune__header">
                <Typography>{item.pageData?.title}</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Accordion elevation={0} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <ImageSearchIcon className="sidebar-fine-tune__box__icon" />
                    <Typography>Select image</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="sidebar-fine-tune__box__product-images">
                        {item?.pageData?.productImages?.map((imageItem, i) => (
                            <div key={i} className={'sidebar-fine-tune__box__product-images__item ' + (i === activeImage ? 'active' : '')} onClick={() => setActiveImage(i)}>
                                <img className="sidebar-fine-tune__box__product-images__content" src={imageItem.src} />
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <ShortcutIcon className="sidebar-fine-tune__box__icon" />
                    <Typography> Prompt</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2} // Adds spacing between the children (TextFields)
                    >
                        {promptContent.slice(0).map((prompt, index) => (
                            <TextField
                                key={index + 1}
                                multiline
                                rows={4}
                                label={`image ${index + 1}`}
                                onChange={(e) => handlePromptChange(index + 1, e.target.value)}
                                value={prompt}
                                fullWidth
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    style: {
                                        fontSize: 14
                                    }
                                }}
                            />
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion elevation={0} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <PhotoSizeSelectLargeIcon className="sidebar-fine-tune__box__icon" />
                    <Typography> Output setting</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ToggleButtonGroup
                        color="primary"
                        value={format}
                        exclusive
                        onChange={(e, newFormat) => setFormat(newFormat)}
                        fullWidth
                        size="small"
                        sx={{
                            mb: 2
                        }}>
                        <ToggleButton value="square">Square</ToggleButton>
                        <ToggleButton value="portrait">Portrait</ToggleButton>
                        <ToggleButton value="landscape">Landscape</ToggleButton>
                    </ToggleButtonGroup>

                    <Typography fontSize="small" gutterBottom>
                        Position on X-axis
                    </Typography>
                    <Slider size="small" defaultValue={50} min={0} max={100} value={xAxis} valueLabelDisplay="auto" onChange={(e, value) => setXAxis(value as number)} />

                    <Typography fontSize="small" gutterBottom>
                        Position on Y-axis
                    </Typography>
                    <Slider size="small" defaultValue={50} min={0} max={100} value={yAxis} valueLabelDisplay="auto" onChange={(e, value) => setYAxis(value as number)} />

                    <Typography fontSize="small" gutterBottom>
                        Scale
                    </Typography>
                    <Slider size="small" defaultValue={50} min={0} max={100} value={scale} valueLabelDisplay="auto" onChange={(e, value) => setScale(value as number)} />
                </AccordionDetails>
            </Accordion>

            <div className="sidebar-fine-tune__footer">
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        boxShadow: 'none'
                    }}
                    onClick={() =>
                        onSubmit({
                            ...item,
                            productImage: item?.pageData?.productImages[activeImage]?.src,
                            prompt: promptContent,
                            format: format,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            image_1: undefined,
                            image_2: undefined,
                            image_3: undefined,
                            image_4: undefined,
                            imagePrompts: promptContent.map((prompt) => ({ prompt }))
                        })
                    }>
                    Generate
                </Button>
            </div>
        </Box>
    );
};

export default SidebarFineTune;
