// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer Section */
.footer {
  background-color: var(--color-background-light);
}
.footer-content {
  display: flex;
  align-items: center;
  padding: 8rem 1.5rem;
  height: 80vh;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.footer-content h2 {
  font-size: 2.5rem;
  margin-bottom: var(--space-16);
}
.footer-content p {
  color: var(--color-text-muted);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.footer-details {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/controllers/Home/components/Footer/style.scss"],"names":[],"mappings":"AAAA,mBAAA;AACA;EACE,+CAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;AACI;EACE,iBAAA;EACA,8BAAA;AACN;AAEI;EACE,8BAAA;EACA,iBAAA;EACA,mBAAA;AAAN;AAIE;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AAFJ","sourcesContent":["/* Footer Section */\n.footer {\n  background-color: var(--color-background-light);\n\n  &-content {\n    display: flex;\n    align-items: center;\n    padding: 8rem 1.5rem;\n    height: 80vh;\n    text-align: center;\n    justify-content: center;\n    flex-direction: column;\n\n    h2 {\n      font-size: 2.5rem;\n      margin-bottom: var(--space-16);\n    }\n\n    p {\n      color: var(--color-text-muted);\n      font-size: 1.2rem;\n      margin-bottom: 1rem;\n    }\n  }\n\n  &-details {\n    display: flex;\n    justify-content: space-between;\n    padding: 2.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
