import React from "react";
import "./style.scss";
import { Topnav, Slider, Intro, ProductExplain, Footer } from "./components";

const Home = () => {
  return (
    <div className="page-home">
      <Topnav />
      <main>
        <Intro />
        <ProductExplain />
        <Slider />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
