import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import { GeneratedItem } from 'types';

interface SidebarProps {
    item: GeneratedItem;
    onClose: () => void;
}

const SidebarDetails: React.FC<SidebarProps> = ({ item, onClose }) => {
    return (
        <Box className="sidebar-fine-tune" sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            <div className="sidebar-details__header">
                <Typography>Product Details</Typography>

                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className="sidebar-details__info">
                <Typography>{item.pageData?.title}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
                    {item.pageData?.description}
                </Typography>
            </div>
        </Box>
    );
};

export default SidebarDetails;
