import React, { useState } from "react";
import sliderImage1 from "./assets/slider-image-1.png";
import sliderImage2 from "./assets/slider-image-2.png";
import sliderImage3 from "./assets/slider-image-3.png";
import "./style.scss";

const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(2);

  const handleButtonClick = (slideNumber) => {
    setActiveSlide(slideNumber);
  };

  return (
    <section className="slider-section">
      <div className="text">
        <h2>Powerfully simple</h2>
        <p>Designed for, and with, advertising people</p>
      </div>
      <div className="slider-section-handlers">
        <div className="toggle-group">
          <button
            className={`toggle-btn ${activeSlide === 1 ? "active" : ""}`}
            onClick={() => handleButtonClick(1)}
          >
            Provide URL
          </button>
          <button
            className={`toggle-btn ${activeSlide === 2 ? "active" : ""}`}
            onClick={() => handleButtonClick(2)}
          >
            Generate
          </button>
          <button
            className={`toggle-btn ${activeSlide === 3 ? "active" : ""}`}
            onClick={() => handleButtonClick(3)}
          >
            Edit content
          </button>
        </div>
      </div>

      <div className="slider-content">
        <div className="slider">
          <div
            className="slides"
            style={{ transform: `translateX(-${(activeSlide - 1) * 100}%)` }}
          >
            <img
              src={sliderImage1}
              className={`slide ${activeSlide === 1 ? "active" : ""}`}
              alt="Slide 1"
            />
            <img
              src={sliderImage2}
              className={`slide ${activeSlide === 2 ? "active" : ""}`}
              alt="Slide 2"
            />
            <img
              src={sliderImage3}
              className={`slide ${activeSlide === 3 ? "active" : ""}`}
              alt="Slide 3"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
