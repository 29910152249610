import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./controllers/Home/index.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ContextProvider } from "./context.js";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme, darkTheme } from "./theme.js"; // Ensure both light and dark themes are imported
import ToolController from "./controllers/Tool/index.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "tool",
    element: <ToolController />,
  },
]);

function App() {
  // Detect the user's preferred theme when the component mounts
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    setIsDarkMode(prefersDarkMode);
    document.body.className = prefersDarkMode ? 'dark-mode' : '';
  }, []); 

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </ContextProvider>
    </ThemeProvider>
  );
}

export default App;
