import React from 'react';
import { Button, TextField, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LinkIcon from '@mui/icons-material/Link';
import TuneIcon from '@mui/icons-material/Tune';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import './style.scss';

const ToolTopBar = ({ onSubmit, onShowInfo, onShowSidebarSetting, onChangeUrl, url }) => {
    // const [showSettingItem, setShowSettingItem] = React.useState(false);
    // const [sidebarSettings, setSidebarSettings] = React.useState({
    //     format: 'square',
    //     xAxis: 50,
    //     yAxis: 50,
    //     scale: 70
    // });

    const [isValid, setIsValid] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleButton = () => {
        const urlRegex = new RegExp('^(http|https)://[^\\s/$.?#].[^\\s]*$', 'i');
        const isValid = urlRegex.test(url);
        setIsValid(isValid);
        if (!isValid) {
            return;
        }

        onSubmit({
            url: url
        });

        window.scrollTo(0, 0);
    };

    const errorMessage = 'Please enter a valid URL.';

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    return (
        <Box className="tool-top-bar" sx={{ backgroundColor: (theme) => theme.palette.background.paper, borderColor: (theme) => theme.palette.background.default }}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleButton();
                }}
                style={{ display: 'flex', width: '100%' }}>
                <TextField
                    placeholder="e.g. https://www.webshop.com/product-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={url}
                    error={!isValid}
                    helperText={!isValid ? errorMessage : ''}
                    onChange={(e) => onChangeUrl(e.target.value)}
                    sx={{ mr: 1 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LinkIcon />
                            </InputAdornment>
                        )
                    }}
                    autoFocus
                />

                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        boxShadow: 'none',
                        height: '40px'
                    }}
                    onClick={() => handleButton()}>
                    Generate
                </Button>

                {isSmallScreen ? (
                    <IconButton onClick={handleMenuClick} sx={{ boxShadow: 'none' }}>
                        <MoreVertIcon />
                    </IconButton>
                ) : (
                    <>
                        <IconButton
                            onClick={onShowSidebarSetting}
                            sx={{
                                boxShadow: 'none',
                                height: '40px'
                            }}>
                            <TuneIcon />
                        </IconButton>
                        <IconButton onClick={onShowInfo}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </>
                )}
            </form>

            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    mt: 1
                }}>
                <MenuItem
                    onClick={() => {
                        onShowSidebarSetting();
                        handleMenuClose();
                    }}>
                    <TuneIcon sx={{ mr: 1 }} /> Settings
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onShowInfo();

                        handleMenuClose();
                    }}>
                    <InfoOutlinedIcon sx={{ mr: 1 }} /> Info
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ToolTopBar;
