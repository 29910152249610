// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showcase {
  display: flex;
  justify-content: center;
  width: calc(100% + 160px);
  gap: 24px;
  position: relative;
  top: -80px;
  z-index: 2;
  margin-left: -80px;
}
.showcase-image {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
}
.showcase-image img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/controllers/Home/components/Intro/components/Showcase/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,yBAAA;EACA,SAAA;EACA,kBAAA;EACA,UAAA;EAEA,UAAA;EACA,kBAAA;AAAF;AAEE;EACE,mBAAA;EACA,gBAAA;EACA,WAAA;AAAJ;AAEI;EACE,WAAA;AAAN","sourcesContent":[".showcase {\n  display: flex;\n  justify-content: center;\n  width: calc(100% + 160px);\n  gap: 24px;\n  position: relative;\n  top: -80px;\n\n  z-index: 2;\n  margin-left: -80px;\n\n  &-image {\n    border-radius: 16px;\n    overflow: hidden;\n    width: 100%;\n\n    img {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
