import React, { createContext, useState } from 'react';

// Create a context
const AppContext = createContext();

// Create a provider component
const ContextProvider = ({ children }) => {
  const [value, setValue] = useState({});

  return (
    <AppContext.Provider value={{ value, setValue }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, ContextProvider };