import axios from 'axios';

const host = 'https://adgenai-acceptance.api.bycape.io/';
const hostAI = 'https://bycape--cape-v1-1-fast-comfyui-api.modal.run';

export const parsePage = async (url) => {
    try {
        const response = await axios.post(host + '/parser/parse', {
            url: url
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const generateCopy = async (data) => {
    try {
        const response = await axios.post(host + '/generate-copy/generate', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const createImages = async (data) => {
    try {
        const response = await axios.post(hostAI, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export { hostAI };
