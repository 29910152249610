import React, { useState, useRef } from 'react';
import './style.scss';
import SidebarInfo from './components/SidebarInfo';
import ToolTopBar from './components/ToolTopBar';
import ListItem from './components/ListItem';
import SidebarFineTune from './components/SidebarFineTune';
import emptystate from './assets/empty-state.png';
// import LimitPopup from './components/LimitPopup';
import SidebarSetting from './SidebarSetting';
import SidebarDetails from './components/SidebarDetails';
import { generateCopy, parsePage } from '../../helpers/requests';
import { Typography } from '@mui/material';
import { createImages } from '../../helpers/requests';

const ToolController = () => {
    const getStorageData = () => {
        let list = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : [];

        if (list.length > 0) {
            list.forEach((item) => {
                if (item.loading) {
                    item.loading = false;
                }
            });
        }

        return list;
    };

    const [url, setUrl] = React.useState('');
    const [overview, setOverview] = React.useState(getStorageData());
    const [showFinetuneItem, setShowFinetuneItem] = React.useState(false);
    const [showDetails, setShowDetails] = React.useState(false);
    const [activeImage, setActiveImage] = useState(0);
    const cancelGenerationRef = useRef(null);
    const triggeredSubmitRef = useRef(false);
    const loading = useRef(false);
    const [showInfo, setShowInfo] = React.useState(false);
    const [showSidebarSetting, setShowSidebarSetting] = React.useState(false);
    const handleSidebarSettingOpen = () => setShowSidebarSetting(true);
    const [sidebarSettings, setSidebarSettings] = React.useState({
        format: 'square',
        xAxis: 50,
        yAxis: 50,
        scale: 70
    });
    const handleSettingSidebarSave = (updatedSettings) => {
        setSidebarSettings(updatedSettings);
        setShowSidebarSetting(false);
    };

    // const [showLimitPopup, setShowLimitPopup] = useState(false);
    // const [hasSubmitted, setHasSubmitted] = useState(false);

    /**
     * Cancle image generation
     */

    const cancelImageGeneration = (uuid) => {
        cancelGenerationRef.current = true;

        // Immediately stop loading and reset progress for the item
        updateItem(uuid, {
            loading: false,
            loadingProgress: 0
        });
    };

    /**
     * Upon loading compoenent, check if there is an import
     */
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const importData = urlParams.get('import');
        const url = urlParams.get('url');
        cancelGenerationRef.current = false;

        if (importData) {
            let importedData = JSON.parse(atob(importData));
            handleImport(importedData);
        }
        if (url && !loading.current) {
            loading.current = true;
            // Check if not already done
            if (!overview.find((item) => item.url === url)) {
                handleSubmit({ url: url });
            } else {
                if (!triggeredSubmitRef.current) {
                    handleSubmit({ url: url });
                }
                triggeredSubmitRef.current = true;
            }
        }
    }, []);

    /**
     * Import data upon load
     * @param {*} importedData
     */
    const handleImport = (importedData) => {
        setOverview((prev) => {
            let newObject = [importedData, ...prev];
            localStorage.setItem('overview', JSON.stringify(newObject));
            return newObject;
        });
    };

    /**
     * Handle submitting of new item
     * @param {*} newItem
     */
    const handleSubmit = (newItem) => {
        setOverview((previousOverview) => {
            const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => (c === 'x' ? (Math.random() * 16) | 0 : ((Math.random() * 16) & 0x3) | 0x8).toString(16));

            newItem.uuid = uuid;
            if (!newItem.title) {
                newItem.title = 'Product ' + (previousOverview.length + 1);
            }

            newItem.loading = true;
            newItem.loadingProgress = 0;

            newItem = { ...newItem, ...sidebarSettings };
            let newOVerviewList = [newItem, ...previousOverview];

            localStorage.setItem('overview', JSON.stringify(newOVerviewList));

            // Call the api to generate the data
            loadPageData(newItem);

            return newOVerviewList;
        });
    };

    /**
     * Handle remove of item
     * @param {*} uuid
     */
    const handleRemove = (uuid) => {
        setOverview((previousOverview) => {
            let newOverview = previousOverview.filter((item) => item.uuid !== uuid);
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    /**
     * Handle tinetune
     * @param {*} item
     */
    const handleFinetune = (item) => {
        setShowFinetuneItem(item);
    };
    const handleDetails = (item) => {
        setShowDetails(item);
    };

    const handleInfo = () => {
        setShowInfo(true);
    };

    /**
     * Handle one generate
     * @param {*} item
     */
    const handleOneGenerate = (item) => {
        setShowFinetuneItem(false);
        delete item.imagePrompts;
        generatePrompts(item, 1);
    };
    /**
     * Regenerate item
     * @param {*} item
     */
    // const handleRegenerate = (updatedItem) => {
    //     /**
    //      * 1. Don't use handleSubmit, because this adds a new one
    //      * 2. We want to call the api again to get new prompts
    //      * 3. Call the api again to generate new images
    //      * 4. Wait for these calls to be finished and until it returns a result (with the new images)
    //      * 5. When waiting, update the item already with a new loading state (updateItem(uuid, loading: true))
    //      * 6. Run the updateItem function for this uuid (updatedItem.uuid, {image_5: newResult.image_1, image_6: newResult})
    //      */

    //     updateItem(updatedItem);
    //     setShowFinetuneItem(false);
    //     window.scrollTo(0, 0);
    // };
    const handleRegenerate = async (updatedItem) => {
        updateItem(updatedItem.uuid, { loading: true, loadingProgress: 1 });

        // Regenerate prompts and images
        const newPromptsResult = await generateCopy({
            title: updatedItem.pageData.title,
            description: updatedItem.pageData.description,
            imageUrl: updatedItem.productImage
        });

        if (newPromptsResult?.imagePrompts) {
            // Update the item with new prompts
            updateItem(updatedItem.uuid, {
                imagePrompts: newPromptsResult.imagePrompts,
                loadingProgress: 1
            });

            // Generate new images with the updated prompts
            await generateImage(
                { ...updatedItem, imagePrompts: newPromptsResult.imagePrompts },
                1,
                undefined,
                4 // Default number of images to generate
            );
        } else {
            // Handle prompt generation failure
            updateItem(updatedItem.uuid, {
                loading: false,
                error: true,
                errorMessage: 'Failed to regenerate prompts'
            });
        }

        // Finalize loading state
        updateItem(updatedItem.uuid, { loading: false });
    };

    /**
     * Start loading page
     * @param {*} newItem
     */
    const loadPageData = async (inputData) => {
        let newData = inputData;

        setUrl('');

        // New page
        if (!inputData?.pageData?.title) {
            let pageData = await parsePage(inputData.url);

            if (!pageData || !pageData.description || !pageData.productImages) {
                updateItem(inputData.uuid, {
                    error: true,
                    loading: false,
                    errorMessage: 'Could not fetch data'
                });
            } else {
                let newData = {
                    ...inputData,
                    title: pageData?.title,
                    productImage: pageData.productImages[0]?.src,
                    pageData: pageData,
                    loadingProgress: 1
                };

                updateItem(newData.uuid, newData);
                await generatePrompts(newData);
            }
        }
        // Regenerate, we have page data
        else {
            await generatePrompts(newData);
        }
    };

    /**
     *
     * Generate prompts with AI
     * @param {*} newData
     */
    const generatePrompts = async (newData, nrOfImages = 4) => {
        if (newData.imagePrompts && newData.imagePrompts.length > 0) {
            updateItem(newData.uuid, {
                loadingProgress: 2
            });
            cancelGenerationRef.current = false;
            await generateImage(newData, 1, undefined, nrOfImages);
        } else {
            let result = await generateCopy({
                title: newData.pageData.title,
                description: newData.pageData.description,
                imageUrl: newData.productImage
            });

            if (result?.imagePrompts) {
                updateItem(newData.uuid, {
                    imagePrompts: result.imagePrompts,
                    loadingProgress: 2
                });
                cancelGenerationRef.current = false;
                generateImage({ ...newData, imagePrompts: result.imagePrompts }, 1, undefined, nrOfImages);
            }
        }
    };

    /**
     * Generate image
     * @param {*} newData
     * @param {*} nr
     */

    const generateImage = async (inputData, nr, totalTime = 0) => {
        const startTime = Date.now();

        let imageOutput = await createImages({
            'x-axis': inputData.xAxis || 50,
            'y-axis': inputData.yAxis || 50,
            scale: inputData.scale || 0.6,
            url: inputData.productImage,
            prompt: inputData.imagePrompts[nr - 1]?.prompt,
            aspect_ratio: 'square'
        });
        const timePassed = (Date.now() - startTime) / (1000 * 60);
        totalTime += timePassed;

        if (imageOutput?.image_urls[0]) {
            updateItem(inputData.uuid, {
                loadingProgress: nr + 2,
                ['image_' + nr]: imageOutput?.image_urls[0],
                loading: nr < 4,
                totalTime
            });
        }
        if (!cancelGenerationRef.current) {
            if (nr < 4) {
                await generateImage(inputData, nr + 1, totalTime);
            }
        }
    };

    /**
     * Set data to update item
     * @param {*} uuid
     * @param {*} data
     */
    const updateItem = (uuid, data) => {
        setOverview((prev) => {
            let newOverview = prev.map((item) => {
                if (item.uuid === uuid) {
                    return { ...item, ...data };
                }
                return item;
            });
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    const handleDeleteImage = (uuid, field) => {
        updateItem(uuid, {
            [field]: undefined
        });
    };

    const formatTime = (timeInMinutes) => {
        if (!timeInMinutes) return;

        const totalSeconds = Math.round(timeInMinutes * 60);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
    };

    return (
        <div className={'tool ' + (showFinetuneItem || showInfo || showSidebarSetting || showDetails ? 'sidebar' : '')}>
            {showFinetuneItem && <SidebarFineTune item={showFinetuneItem} onSubmit={handleRegenerate} activeImage={activeImage} setActiveImage={setActiveImage} onClose={() => setShowFinetuneItem(false)} />}
            {showInfo && <SidebarInfo onClose={() => setShowInfo(false)} />}

            {showSidebarSetting && (
                <SidebarSetting
                    sidebarSettings={sidebarSettings}
                    url={url}
                    onClose={() => setShowSidebarSetting(false)}
                    onChange={(updatedSettings) => {
                        setSidebarSettings(updatedSettings);
                    }}
                    onSubmit={handleSettingSidebarSave}
                    onGenerate={handleSubmit}
                />
            )}

            {showDetails && <SidebarDetails item={showDetails} onClose={() => setShowDetails(false)} />}
            <ToolTopBar onSubmit={handleSubmit} onShowInfo={handleInfo} onShowSidebarSetting={handleSidebarSettingOpen} onChangeUrl={(url) => setUrl(url)} url={url} />

            {(!overview || overview.length === 0) && (
                <div className="tool__placeholder">
                    <img src={emptystate} alt="Empty state" />
                    <div className="tool__placeholder__text">
                        <Typography variant="h1">No generations yet!</Typography>
                        <Typography variant="body1" color="textSecondary">
                            Generate captivating visuals from your product URL. Please enter it in the top bar above.
                        </Typography>
                    </div>
                </div>
            )}

            <div className={'tool__list'}>
                {overview.map((item) => (
                    <ListItem
                        item={item}
                        key={item.uuid}
                        onRemove={() => handleRemove(item.uuid)}
                        onDeleteImage={handleDeleteImage}
                        onFinetuneSetting={() => handleFinetune(item)}
                        onOneRegenerate={handleOneGenerate}
                        activeImage={activeImage}
                        setActiveImage={setActiveImage}
                        queuePosition={item.queue_position}
                        onGenerateMore={handleRegenerate}
                        cancelButton={() => cancelImageGeneration(item.uuid)}
                        handleDetails={() => handleDetails(item)}
                        totalTime={formatTime(item.totalTime)}
                    />
                ))}
            </div>

            {/* <LimitPopup open={showLimitPopup} onClose={() => setShowLimitPopup(false)} onGenerateMore={() => setHasSubmitted(true)} /> */}
        </div>
    );
};

export default ToolController;
