import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const InputField = () => {
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const navigate = useNavigate();

    const handleLink = () => {
        if (value) {
            navigate(`/tool?url=${value}`);
        }
    };

    return (
        <div className={`inputContainer ${isFocused ? 'focused' : ''}`}>
            <input
                type="text"
                autoFocus
                value={value}
                placeholder="e.g., https://webshop.com/product-1"
                className={`input ${value === '' ? 'empty' : ''}`}
                onChange={(e) => setValue(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            <button className="btn btn-primary" onClick={handleLink}>
                Try it out
            </button>
        </div>
    );
};

export default InputField;
