// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* style.scss */
/* Base fade-in styles */
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-top {
  transform: translateY(40px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.fade-in-top.active {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-bottom {
  transform: translateY(-100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.fade-in-bottom.active {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-40px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.fade-in-left.active {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-right {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.fade-in-right.active {
  opacity: 1;
  transform: translateX(0);
}`, "",{"version":3,"sources":["webpack://./src/controllers/Home/animations.scss"],"names":[],"mappings":"AAAA,eAAA;AAEA,wBAAA;AACA;EACE,UAAA;EACA,0DACE;AADJ;AAKE;EACE,UAAA;EACA,wBAAA;AAHJ;;AAQA;EACE,2BAAA;EACA,0DACE;AANJ;AASE;EACE,UAAA;EACA,wBAAA;AAPJ;;AAYA;EACE,6BAAA;EACA,0DACE;AAVJ;AAaE;EACE,UAAA;EACA,wBAAA;AAXJ;;AAgBA;EACE,UAAA;EACA,4BAAA;EACA,0DACE;AAdJ;AAiBE;EACE,UAAA;EACA,wBAAA;AAfJ;;AAoBA;EACE,UAAA;EACA,2BAAA;EACA,0DACE;AAlBJ;AAqBE;EACE,UAAA;EACA,wBAAA;AAnBJ","sourcesContent":["/* style.scss */\n\n/* Base fade-in styles */\n.fade-in {\n  opacity: 0; // Start fully transparent\n  transition:\n    opacity 0.5s ease-out,\n    transform 0.5s ease-out;\n\n  // Default fade-in from top\n  &.active {\n    opacity: 1; // Fully visible\n    transform: translateY(0); // Reset position\n  }\n}\n\n// Fade-in from top\n.fade-in-top {\n  transform: translateY(40px); // Move down initially\n  transition:\n    opacity 0.5s ease-out,\n    transform 0.5s ease-out;\n\n  &.active {\n    opacity: 1; // Fully visible\n    transform: translateY(0); // Reset position\n  }\n}\n\n// Fade-in from bottom\n.fade-in-bottom {\n  transform: translateY(-100px); // Move up initially\n  transition:\n    opacity 0.5s ease-out,\n    transform 0.5s ease-out;\n\n  &.active {\n    opacity: 1; // Fully visible\n    transform: translateY(0); // Reset position\n  }\n}\n\n// Fade-in from left\n.fade-in-left {\n  opacity: 0;\n  transform: translateX(-40px); // Move right initially\n  transition:\n    opacity 0.5s ease-out,\n    transform 0.5s ease-out;\n\n  &.active {\n    opacity: 1; // Fully visible\n    transform: translateX(0); // Reset position\n  }\n}\n\n// Fade-in from right\n.fade-in-right {\n  opacity: 0;\n  transform: translateX(20px); // Move left initially\n  transition:\n    opacity 0.5s ease-out,\n    transform 0.5s ease-out;\n\n  &.active {\n    opacity: 1; // Fully visible\n    transform: translateX(0); // Reset position\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
