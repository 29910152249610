import React from 'react';
import './style.scss';
import logo from './assets/logo.svg';
import logoCape from './assets/logoCape.svg';

const Topnav = () => {
    return (
        <header className="topbar">
            <div className="logo">
                {' '}
                <img src={logo} alt="ProdShot.io Logo - AI generated product image" />
            </div>
            <a href="https://www.bycape.io" rel="noreferrer" target="_blank">
                <img src={logoCape} alt="Logo Product Shot" />
            </a>
        </header>
    );
};

export default Topnav;
