import React from "react";
import "./style.scss";
import "../../../../animations.scss";
import useScrollPosition from "../../../../hooks/scrollPositionAnimation";

const images = require.context("./assets", false, /\.(jpg|jpeg|png|gif)$/);
const imageArray = images.keys().map((image) => images(image));

const Showcase = () => {
  const scrollY = useScrollPosition();

  // Apply transform values based on scrollY for a parallax-like effect
  const animateShowcase = {
    transform: `translateX(${scrollY * -0.05}px)`, // Adjust multiplier for desired effect
    //opacity: Math.min(1, 1 - scrollY / 600), // Fade out as it scrolls up
  };

  return (
    <div className="showcase" style={animateShowcase}>
      {imageArray.map((image, index) => (
        <div className="showcase-image" key={index}>
          <img src={image} alt={`Product Shot ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};
export default Showcase;
