import React, { useState } from 'react';
import { Box, Button, CircularProgress, LinearProgress, Typography, useMediaQuery, useTheme, Avatar, ButtonGroup, Skeleton, ToggleButton } from '@mui/material';
import './style.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FullscreenImage from '../FullscreenImage';
import TuneIcon from '@mui/icons-material/Tune';
import IosShareIcon from '@mui/icons-material/IosShare';

import Tooltip from '@mui/material/Tooltip';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { GeneratedItem } from 'types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface ListItemProps {
    item: GeneratedItem;
    onRemove: () => void;
    onFinetuneSetting: () => void;
    onDeleteImage: (uuid: string, imageKey: string) => void;
    onOneRegenerate: (item: GeneratedItem, index: number) => void;
    activeImage: number;
    setActiveImage: React.Dispatch<React.SetStateAction<number>>;
    cancelButton?: () => void;
    queuePosition: number;
    totalTime: number;
    onGenerateMore: (data: any) => void;
    handleDetails: () => void;
}

const ListItem: React.FC<ListItemProps> = ({ item, onRemove, onFinetuneSetting, onDeleteImage, onOneRegenerate, activeImage, queuePosition, setActiveImage, cancelButton, totalTime, onGenerateMore, handleDetails }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showFullscreenImage, setShowFullscreenImage] = useState(false);
    const [fullscreenImageIndex, setFullscreenImageIndex] = useState<number | null>(null);

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [regeneratingImageIndices, setRegeneratingImageIndices] = useState<number[]>([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDownloadImage = async (imageUrl: string) => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${imageUrl}`);
            }
            const imageBlob = await response.blob();
            const fileName = imageUrl.split('/').pop() || 'image';
            saveAs(imageBlob, fileName);
        } catch (error) {
            console.error('Download error:', error);
        }
    };
    const handleDownload = async () => {
        const zip = new JSZip();
        const urlArray: string[] = [];
        for (let i = 1; i < 5; i++) {
            const imageKey = `image_${i}`;
            if (item[imageKey]) {
                urlArray.push(item[imageKey]!);
            }
        }

        const fetchImage = async (url: string): Promise<Blob> => {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch ${url}`);
            }
            return response.blob();
        };

        try {
            for (const url of urlArray) {
                const imageBlob = await fetchImage(url);
                const fileName = url.split('/').pop() || 'image';
                zip.file(fileName, imageBlob);
            }

            const content = await zip.generateAsync({ type: 'blob' });
            saveAs(content, 'images.zip');
        } catch (error) {
            console.error(error);
        }
    };

    const handleShare = () => {
        const shareLink = `https://acceptance.prodshot.io/tool?import=${item.uuid}`;
        navigator.clipboard.writeText(shareLink);
        alert('Copied to clipboard');
    };

    const handleRegenerateImage = (index: number) => {
        setRegeneratingImageIndices((prevState) => [...prevState, index]);
        onOneRegenerate(item, index);
    };

    return (
        <Box className="tool-list-item" sx={{ backgroundColor: (theme) => theme.palette.background.paper, borderColor: (theme) => theme.palette.divider }}>
            {showFullscreenImage && fullscreenImageIndex !== null && (
                <FullscreenImage
                    nr={fullscreenImageIndex}
                    item={item}
                    onClose={() => {
                        setShowFullscreenImage(false);
                        setFullscreenImageIndex(null);
                    }}
                />
            )}

            {item.loading && <LinearProgress variant="determinate" value={(item.loadingProgress! / 6) * 100} />}

            <div className="tool-list-item__header">
                <Avatar variant="rounded" sx={{ backgroundColor: (theme) => theme.palette.background.default }} className="tool-list-item__header__icon">
                    {item.loading && <CircularProgress size={24} />}
                    {!item.loading && !item.error && <CheckCircleOutlineIcon fontSize="inherit" color="success" />}
                    {item.error && <ErrorOutlineIcon fontSize="inherit" color="error" />}
                </Avatar>

                <div className="tool-list-item__header__context">
                    <Typography variant="h5">{item.pageData?.title}</Typography>
                    <div className="tool-list-item__header__position">
                        {item.loading && queuePosition !== undefined && <Typography variant="caption">{`Queue Position: ${queuePosition}`}</Typography>}
                        {!item.loading && totalTime !== undefined && <Typography variant="caption">{`Total Time: ${totalTime}`}</Typography>}
                        <a href={item.url} className="tool-list-item__header__url" target="_blank" rel="noreferrer">
                            <Typography variant="caption">{item.url}</Typography>
                        </a>
                    </div>
                </div>

                <div className="tool-list-item__header__actions">
                    <IconButton size="small" onClick={handleDownload}>
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                    <IconButton size="small" onClick={onRemove}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                    <IconButton size="small" onClick={handleShare}>
                        <IosShareIcon sx={{ color: (theme) => theme.palette.action.active }} />
                    </IconButton>
                    <ToggleButton value="check" onClick={handleDetails}>
                        <InfoOutlinedIcon />
                    </ToggleButton>
                </div>
            </div>

            <div className="tool-list-item__content">
                <Box className="tool-list-item__content__left">
                    <Tooltip title="Select scraped image for generating new.">
                        <div className="tool-list-item__content__product-images">
                            {item.pageData?.productImages?.map((imageItem, i) => (
                                <div key={i} className={`tool-list-item__content__product-images__item ${i === activeImage ? 'active' : ''}`} onClick={() => setActiveImage(i)}>
                                    <img className="tool-list-item__content__product-images__content" src={imageItem.src} alt="" />
                                </div>
                            ))}
                        </div>
                    </Tooltip>
                    <Box onClick={() => setShowFullDescription(!showFullDescription)} className={`tool-list-item__content__description ${showFullDescription ? 'full' : ''}`} sx={{ color: (theme) => theme.palette.text.primary }}>
                        {item.pageData?.description}
                    </Box>
                </Box>

                <div className="tool-list-item__content__right">
                    {[1, 2, 3, 4].map((index) => {
                        const imageKey = `image_${index}` as keyof GeneratedItem;
                        return item[imageKey] ? (
                            <div
                                key={imageKey}
                                className="tool-list-item__content__image"
                                onClick={() => {
                                    setShowFullscreenImage(true);
                                    setFullscreenImageIndex(index);
                                }}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}>
                                {regeneratingImageIndices.includes(index) ? (
                                    <>
                                        <Skeleton className="tool-list-item__content__image__skeleton" variant="rectangular" animation="wave" /> <CircularProgress />
                                    </>
                                ) : (
                                    <img className="tool-list-item__content__image__content" src={item[imageKey] as string} alt={`Image ${index}`} />
                                )}
                                {hoveredIndex === index && !regeneratingImageIndices.includes(index) && (
                                    <div className="tool-list-item__hover-content">
                                        <Tooltip title="Delete">
                                            <IconButton
                                                size="small"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    onDeleteImage(item.uuid ?? '', imageKey);
                                                }}
                                                sx={{
                                                    backgroundColor: (theme) => theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    '&:hover': {
                                                        backgroundColor: (theme) => theme.palette.background.paper
                                                    }
                                                }}>
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Regenerate">
                                            <IconButton
                                                size="small"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleRegenerateImage(index);
                                                }}
                                                sx={{
                                                    backgroundColor: (theme) => theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    '&:hover': {
                                                        backgroundColor: (theme) => theme.palette.background.paper
                                                    }
                                                }}>
                                                <AutorenewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Download">
                                            <IconButton
                                                size="small"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDownloadImage(item[imageKey] as string);
                                                }}
                                                sx={{
                                                    backgroundColor: (theme) => theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    '&:hover': {
                                                        backgroundColor: (theme) => theme.palette.background.paper
                                                    }
                                                }}>
                                                <FileDownloadOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        ) : item.loading ? (
                            <Box className="tool-list-item__content__image">
                                <Skeleton className="tool-list-item__content__image__skeleton" variant="rectangular" animation="wave" />
                            </Box>
                        ) : null;
                    })}
                </div>
            </div>

            {/* <div className="tool-list-item__queue">
                {item.loading && queuePosition !== undefined && queuePosition > 0 && (
                    <Box>
                        <Chip label={`Queue Position: ${queuePosition}`} size="small" variant="outlined" />
                    </Box>
                )}
            </div> */}

            {item.loading && cancelButton && (
                <div className="tool-list-item__footer">
                    <Button variant="contained" color="primary" fullWidth={isMobile} onClick={cancelButton}>
                        Cancel Generation
                    </Button>
                </div>
            )}

            {!item.loading && (
                <div className="tool-list-item__footer">
                    {/* <Button
                        variant="contained"
                        color="primary"
                        fullWidth={isMobile}
                        endIcon={<TuneIcon />}
                        onClick={onFinetuneSetting}
                        sx={{
                            boxShadow: 'none'
                        }}>
                        Regenerate
                    </Button> */}

                    <ButtonGroup disableElevation variant="contained" aria-label="Disabled button group">
                        <Button
                            onClick={() =>
                                onGenerateMore({
                                    ...item,
                                    image_1: undefined,
                                    image_2: undefined,
                                    image_3: undefined,
                                    image_4: undefined
                                })
                            }>
                            Generate more
                        </Button>
                        ;
                        <Button onClick={onFinetuneSetting}>
                            <TuneIcon />
                        </Button>
                    </ButtonGroup>
                </div>
            )}
        </Box>
    );
};

export default ListItem;
